
import "./hero.css";

function HeroBG() {
  

  return (
    <>
      <div className="BG">
        <h1>
          40+Years of Expertise to Simplify Your Procedures </h1><h2> Expedite Shipments Worldwide
        </h2>
        <h3>Streamline Your Customs Procedures with <span><h3>UDHAY SHIPPING</h3></span></h3>
       
  
      </div>
    </>
  );
}
export default HeroBG;
